import Vue from 'vue'
import Cart from '../models/Cart.js'
import Transaction from '../models/Transaction.js'
import Tender from '@grantstreet/e-wallet-vue/src/store/tender.js'
import { decimalFormat, displayFormat, parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { sentryException } from '../sentry.js'
import { inflateCartData, inflateTransaction } from './helpers.js'
import { searchPayables } from '@grantstreet/payables'
import { canUseApplePay } from '@grantstreet/psc-js/utils/apple-pay.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import { configState, configGetters } from '@grantstreet/psc-config'

const methodAlertedCarts = {}

export default {
  namespaced: true,

  state: {
    cart: new Cart(),
    checkoutNavSteps: [],
    billing: null,
    chosenAddress: null,
    urls: null,
    chosenTender: null,
    extraFields: null,

    // We store the bank account number here because it is needed
    // for delayed $0 auth bank checkouts, specifically checkout on
    // the confirmation page. This is stored differently from
    // chosenTender so it is not exposed to other modules
    bankAccountNumber: null,

    merchantIDs: null,
    convenienceFees: {},
    cartLoadPromise: null,
    // Warning when prepopulation of cart fails because an item is no longer payable
    prepopulateCartWarnings: [],

    // Used for checkout widget
    showSaveCheckbox: true,

    // This is either "none", "shipping", or "pickUp", not to be confused with
    // `selectedDeliveryMethod`
    deliveryOption: 'none',
    // This is the `selected` property of Delivery Method, where it can
    // look like "billing", "onFile", "address[UUID]", or just "pickUp"; not to
    // be confused with `deliveryOption`
    selectedDeliveryMethod: '',

    changeAddress: false,
    requiredPermanentAddressChange: false,
    pickUpInfo: {},
    // This will be undefined or an adaptor string
    restrictedAdaptor: null,
    // This will be undefined or an item_category string
    restrictedItemCategory: null,
    confirmedItemIds: [],
    // Probably would be better to default to null
    transactions: [],
    totals: null,

    autoenrollRenewals: null,
  },

  getters: {
    client: () => configState.config.client,
    site: () => configState.config.site,

    // where to stash the cart id
    // TODO: key by user-id, too
    localStorageKey: (state, getters) => `@grantstreet/cart-vue.${getters.client}.${getters.site}.id`,

    // cart model
    cart: state => state.cart,

    // shortcut for the cart id
    cartId: state => state.cart.id,

    // shortcut for the list of items/payables
    items: state => state.cart.items,

    // shortcut for if the cart is locked
    locked: state => state.cart.locked,

    itemById: state => id => state.cart.items.find(item => item.id === id),

    itemByPath: state => path => state.cart.items.find(item => item.payable.path === path),

    // only the Payable objects from the cart items
    itemPayables: state => state.cart.items.map(item => item?.payable),

    // shortcut for the count
    count: state => state.cart.count,

    billing: state => state.billing,

    urls: state => state.urls,

    isInCart: state => payable => Boolean(state.cart.isInCart(payable)),

    checkoutNavSteps: state => state.checkoutNavSteps,

    chosenTender: state => state.chosenTender,

    chosenAddress: state => state.chosenAddress,

    deliveryOption: state => state.deliveryOption,

    selectedDeliveryMethod: state => state.selectedDeliveryMethod,

    pickUpInfo: state => state.pickUpInfo,

    extraFields: state => state.extraFields,

    merchantIDs: state => state.merchantIDs,

    // Returns the fee for the chosen tender (which is set on E-Wallet
    // _submission_, not as soon as the user selects a different tender)
    chosenConvenienceFee: ({ totals, convenienceFees }, { chosenTender }) => {
      if (totals) {
        return displayFormat(parseNumber(totals.immediateFeeTotal) + parseNumber(totals.delayedFeeTotal))
      }
      else if (chosenTender?.type) {
        return convenienceFees[chosenTender.type]
      }
      return ''
    },

    // Returns the site settings boolean indicating whether we want to display fees as TBD
    // before we know what they'll be
    displayFeesAsTBD: (state) => {
      return configState.config.cart?.displayFeesAsTBD
    },

    // Returns all the disabled card brands of this cart. The return array
    // will be a union of disabled card brands of the items in the cart.
    // disabledCardBrand is set by the payable's adaptor and is passed to ewallet.
    //
    // Possible values for disabled card brands are:
    // VISA, Mastercard, American Express, Discover Card
    disabledCardBrands: (state) => {
      const disabledCardBrandsSet = new Set()

      state.cart.items.forEach((item) => {
        item.payable.disabledCardBrands?.forEach((cardBrand) => {
          disabledCardBrandsSet.add(cardBrand)
        })
      })

      return Array.from(disabledCardBrandsSet)
    },

    createAndInflateTransaction: (state, getters, rootState, rootGetters) => transaction => new Transaction(
      inflateTransaction(
        transaction,
        getters.enrolledCartItemsById,
      ),
    ),

    enrolledCartItemsById: (state, getters) => getters.cart.items.reduce((map, item) => {
      if (item.enrollInAutopay) {
        map[item.id] = item
      }
      return map
    }, {}),

    cartLoadPromise: state => state.cartLoadPromise,

    // We're conflating several things here with our naming scheme. Historically
    // we've used "payment methods" to refer to tender types, eWallet forms, and
    // other things. This wasn't a notable issue when all of those were
    // one-to-one, with just card and bank as options. Now this has become more
    // than a little messy and ambiguous.
    //
    // We've found that "methods" isn't a clear and meaningful term to use in a
    // technical setting. It doesn't clearly or consistently mean "tender
    // types", "tender sources", "payment/tender apis", or "payment
    // processors/facilitators/'means'". It seems to be best used as a user
    // facing term, meaning something like "payment forms" in a technical
    // setting.
    //
    // Going forward we'll try to refer to things this way.
    //
    // Tender Types These are defined by PEx and matched by our Tender model.
    // They are the actual tender types recognized by PEx, and aren't one-to-one
    // with forms or sources. They are:
    // - card
    // - bank
    // - paypal
    //
    // Tender Sources These are the sources from which we can get tenders/tender
    // information.
    // Alternate Tender Sources (These are just cards):
    // - Apple Pay
    // - Google Pay
    // Primary: These are implied, and so far never referenced.
    // - Manual Entry
    //
    // Payment Forms So far this is the intersection of all our tender types and
    // our tender sources (with the types taking the place of "manual entry").
    //
    // It's probably best that we work on phasing out use of the term "method"
    // entirely.
    siteEnabledPaymentMethods: (state, getters, rootState) => configState.config.eWallet.allowedMethods,

    // Returns the payment methods allowed for paying this cart, by finding the
    // intersection of the site's configured methods and the allowed methods for
    // each payable in the cart. Returns an empty array if no methods are
    // allowed.
    allowedPayableMethods: (state, getters, rootState) => {
      // Start off with the site-configured methods
      let allowed = getters.siteEnabledPaymentMethods

      // Remove methods if a payable doesn't allow one.
      for (const item of state.cart.items) {
        // Get updated allowed tender types if it exists
        const allowTenderTypes = item.updatedPayable ? item.updatedPayable.allowedTenderTypes : item.payable.allowedTenderTypes

        // This used to ignore restrictions from payables that don't allow any
        // methods. Now it will respects those payables.
        if (!allowTenderTypes?.length) {
          sentryException(new Error(`Cart Error: Found payable with no allowed methods. Payable Path: ${item.payable.path}`))
          return []
        }

        allowed = allowed.filter(method => allowTenderTypes.includes(method))
      }

      if (!allowed.length && !methodAlertedCarts[state.cart.id]) {
        // Only alert once per cart since this getter can be called frequently
        methodAlertedCarts[state.cart.id] = true
        sentryException(new Error(`Cart Error: No intersection between payable allowed methods and site allowed methods. Payable Paths: ${state.cart.items.map(({ payable: { path } }) => path)}.`))
      }

      return allowed
    },

    restrictedPayableMethods: (state, getters) => {
      const restricted = []
      for (const method of getters.allowedPayableMethods) {
        for (const item of state.cart.items) {
          if (item.payable.isTenderRestricted(method)) {
            restricted.push(method)
            break
          }
        }
      }

      return restricted
    },

    // allowedPayableMethods (which are displayed)
    // minus restrictedPayableMethods (which are disabled)
    enabledPayableMethods: (state, getters) =>
      getters.allowedPayableMethods.filter(method => !getters.restrictedPayableMethods.includes(method)),

    siteEnabledAlternativeTenderSources: () => {
      const { useApplePay, useGooglePay } = configGetters
      const alternatives = []
      if (useApplePay) {
        alternatives.push('apple')
      }
      if (useGooglePay) {
        alternatives.push('google')
      }
      return alternatives
    },

    allowedAlternativeTenderSources: (state, getters) => {
      let alternatives = getters.siteEnabledAlternativeTenderSources

      if (!canUseApplePay(sentryException)) {
        alternatives = alternatives.filter(source => source !== 'apple')
      }

      // Apply any other filtering or restrictions here

      return alternatives
    },

    cardsEnabled: (state, getters) => getters.enabledPayableMethods.includes('card'),
    banksEnabled: (state, getters) => getters.enabledPayableMethods.includes('bank'),
    payPalEnabled: (state, getters) => getters.enabledPayableMethods.includes('paypal'),

    // Important distinction
    cardsSiteEnabled: (state, getters) => getters.siteEnabledPaymentMethods.includes('card'),
    banksSiteEnabled: (state, getters) => getters.siteEnabledPaymentMethods.includes('bank'),
    payPalSiteEnabled: (state, getters) => getters.siteEnabledPaymentMethods.includes('paypal'),

    prepopulateCartWarnings: state => state.prepopulateCartWarnings,

    allItemsConfirmed: (state) => {
      const itemIdsRequiringConfirmation = new Set(state.cart.items.filter(i => i.payable.requiresConfirmation).map(i => i.id))

      for (const id of state.confirmedItemIds) {
        itemIdsRequiringConfirmation.delete(id)
      }

      return itemIdsRequiringConfirmation.size === 0
    },

    cartItemsAllowPickUp: (state) => {
      for (const item of state.cart.items) {
        const path = item.payable.raw.path
        if (path.search(/type=renewal/) < 0) {
          continue
        }

        if (!item.payable.customParameters.can_be_picked_up) {
          return false
        }
      }

      return true
    },

    canAddToCart: (state, getters, rootState, rootGetters) => payable => {
      if (!payable) {
        return false
      }

      if (state.cart.locked) {
        return false
      }

      // If this is a parent with children that can be added, we want show an
      // "add all" button associated with the parent, so we might return true
      // here. This is a quirk, because it doesn't necessarily mean that the
      // parent _itself_ is being added to the cart.
      const hasAddableChildren = payable.canHaveChildren &&
        payable.childPayables?.some(getters.canAddToCart)

      if (!payable.isPayable && !hasAddableChildren) {
        return false
      }

      if (!payable.isQuantityModifiable && getters.isInCart(payable)) {
        return false
      }

      if (
        getters.payableWouldBlockAllTenders(payable) ||
        getters.payableWouldBlockByRestrictionLevel(payable)
      ) {
        return false
      }

      if (getters.wouldCreateMixedCart(payable)) {
        return false
      }

      if (
        payable.canHaveChildren &&
        !payable.isPayable &&
        payable.childPayables?.some(
          child => rootGetters['wait/is'](`adding ${child.path} to cart`),
        )
      ) {
        return false
      }

      const parentPath = payable.parent?.path
      if (parentPath && rootGetters['wait/is'](`adding ${parentPath} to cart`)) {
        return false
      }

      return !rootGetters['wait/is'](`adding ${payable.path} to cart`)
    },

    payableWouldBlockAllTenders: (state, getters) => payable => {
      const methods = [...getters.enabledPayableMethods]

      // Safe loop for modifying contents
      let i = methods.length
      while (i--) {
        for (const child of payable.allPayables) {
          if (child.isTenderRestricted(methods[i])) {
            methods.splice(i, 1)
            break
          }
        }
      }
      return !methods.length
    },

    payableWouldBlockByRestrictionLevel: (state, getters) => payable => {
      // Block an adaptor restricted item if there's already thing(s) in the cart
      // and the items are not the same adaptor
      if (payable.cartRestrictionLevel === 'adaptor') {
        return getters.count > 0 &&
          state.restrictedAdaptor !== payable.adaptor
      }
      else if (payable.cartRestrictionLevel === 'item_category') {
        return getters.count > 0 &&
          state.restrictedItemCategory !== payable.itemCategory
      }

      // Block adding items if there's already an adaptor restricted item
      // and the new item has a different adaptor
      else if (state.restrictedAdaptor && state.restrictedAdaptor !== payable.adaptor) {
        return true
      }
      else if (state.restrictedItemCategory && state.restrictedItemCategory !== payable.itemCategory) {
        return true
      }

      return false
    },

    wouldCreateMixedCart: (state, getters) => payable => {
      // Cannot add immediate item to a cart with delayed items
      if (!payable.shouldDelayPayment && state.cart.hasDelayedItems) {
        return true
      }

      // Cannot add delayed item to a cart with immediate items
      if (payable.shouldDelayPayment && state.cart.hasImmediateItems) {
        return true
      }

      return false
    },

    // Are we currently adding this payable or (if the parent is not addable)
    // any of its children to the cart?
    currentlyAddingToCart: (state, getters, rootState, rootGetters) => payable => {
      return payable.allPayables.some(
        payable => rootGetters['wait/is'](`adding ${payable.path} to cart`),
      )
    },

    hasMultipleItemCategories: (state) => {
      const items = state.cart.items
      if (items?.length < 2) {
        return false
      }

      const aCategory = items[0].payable.itemCategory
      for (const item of items) {
        if (item.payable.itemCategory !== aCategory) {
          return true
        }
      }

      return false
    },

    isEnrollingInAutopay: (state, getters) => payablePath => {
      const item = state.cart.items?.find(item => item.path === payablePath)
      if (!item) return false
      return item.enrollInAutopay
    },
    transactionIDs: (state) => {
      return state.transactions?.map(transaction => transaction.id) || []
    },
  },

  mutations: {
    setClient (state, client) {
      state.client = client
    },

    setSite (state, site) {
      state.site = site
    },

    /**
     * You shouldn't be using this. Use inflateAndUpdateCart instead. The
     * store's authoritative copy of the cart should always be inflated properly
     * and we should always be using Cart/inflateAndUpdateCart for that.
     */
    internalCartSetter (state, { cart, urls, billing }) {
      state.cart = cart
      state.urls = urls
      state.billing = billing
    },

    setCheckoutNavSteps (state, checkoutNavSteps) {
      Vue.set(state, 'checkoutNavSteps', checkoutNavSteps)
    },

    setChosenTender (state, { tender, extraFields }) {
      Vue.set(state, 'chosenTender', tender)
      Vue.set(state, 'extraFields', extraFields)
    },

    setBankAccountNumber (state, bankAccountNumber) {
      Vue.set(state, 'bankAccountNumber', bankAccountNumber)
    },

    setShowSaveCheckbox (state, showSaveCheckbox) {
      state.showSaveCheckbox = showSaveCheckbox
    },

    setChosenAddress (state, address) {
      Vue.set(state, 'chosenAddress', address)
    },

    setDeliveryOption (state, option) {
      state.deliveryOption = option
    },

    setSelectedDeliveryMethod (state, method) {
      state.selectedDeliveryMethod = method
    },

    setChangeAddress (state, changeAddress) {
      state.changeAddress = changeAddress
    },

    setRequiredPermanentAddressChange (state, required) {
      state.requiredPermanentAddressChange = required
    },

    setPickUpInfo (state, info) {
      state.pickUpInfo = info
    },

    clearChosenAddress (state) {
      Vue.delete(state, 'chosenAddress')
    },

    setConvenienceFees (state, fees) {
      state.convenienceFees = fees
    },

    setCartLoadPromise (state, cartLoadPromise) {
      state.cartLoadPromise = cartLoadPromise
    },

    setMerchantIDs (state, merchantIDs) {
      Vue.set(state, 'merchantIDs', merchantIDs)
    },

    setPrepopulateCartWarnings (state, warnings) {
      Vue.set(state, 'prepopulateCartWarnings', warnings)
    },

    setRestrictedAdaptor (state, restrictedAdaptor) {
      Vue.set(state, 'restrictedAdaptor', restrictedAdaptor)
    },

    setRestrictedItemCategory (state, restrictedItemCategory) {
      Vue.set(state, 'restrictedItemCategory', restrictedItemCategory)
    },

    setTransactions (state, transactions) {
      state.transactions = transactions
    },

    setTotals (state, totals) {
      state.totals = totals
    },

    setConfirmedItemIds (state, ids) {
      state.confirmedItemIds = ids
    },

    resetConfirmedItemIds (state) {
      state.confirmedItemIds = []
    },

    enrollPayableInAutoPay (state, payablePath) {
      const item = state.cart.items.find(item => item.payable.path === payablePath)
      if (!item) {
        return
      }
      item.enrollInAutopay = true
      if (item.payable.isYearlyAutopayEligible) {
        // This mutation only gets called as a callback action. If this
        // payable supports yearly autopay, the user had to decline the yearly
        // autopay modal in order for this callback action to occur. We should
        // 'restore' that choice.
        state.autoenrollRenewals = false
      }
    },
  },

  actions: {

    /**
     * This is how you update the store's authoritative cart instance.
     * Always use this action to update the store; attempt to set or commit
     * directly.
     * 1. Inflates raw cart data.
     * 2. Merges with previous cart.
     * 3. Internally commits cart, urls, and billing.
     * 4. Initiates local watching for cart expiration.
     * 5. Triggers fee updates/calculations.
     */
    async inflateAndUpdateCart ({ state, commit, dispatch }, cartData) {
      // When ODMV payables have their pickup option changed, the payable is
      // replaced in the cart, which will reset the autopay enrollment
      // selection. After the new cart is inflated, if any of the new payable
      // save paths match those that were previously saved, autopay will be
      // re-enabled.
      const autopayData = {
        // payableSavePath: agreedTerms
      }
      state?.cart?.items
        ?.filter(item => item.enrollInAutopay)
        ?.forEach(item => {
          autopayData[item.payable.savePath] = item.scheduledPaymentAgreement
        })
      // Always inflate cart data.
      const inflatedCartData = inflateCartData(cartData)

      // restore autopay information
      for (let i = 0; i < inflatedCartData.items.length; i++) {
        const item = inflatedCartData.items[i]
        if (item.payable.savePath in autopayData) {
          inflatedCartData.items[i].enrollInAutopay = true
          inflatedCartData.items[i].scheduledPaymentAgreement = autopayData[item.payable.savePath]
        }
      }

      const updatedCart = new Cart({
        ...state.cart,
        id: inflatedCartData.id,
        items: inflatedCartData.items || [],
        secondsToExpiry: inflatedCartData.seconds_to_expiry,
        locked: inflatedCartData.locked,
      })

      // Don't do this from anywhere else. We want the store's authoritative
      // cart instance to always be properly inflated.
      commit('internalCartSetter', {
        cart: updatedCart,
        urls: inflatedCartData.urls,
        billing: inflatedCartData.billing,
      })

      // TODO: What happens to the old cart's expiration watching?
      updatedCart.checkForExpiration()
      return dispatch('calculateFees')
    },

    // Note: Each of these makes an API call and then updates the entire cart.

    // create: If true and there is no cart ID in localStorage or the getCart
    //         call fails, a new cart will be created.
    // merge:  If true and the logged-in user already has a cart, merge that
    //         in to the current cart.
    //
    async loadCart ({ getters, rootGetters, dispatch }, { create = true, merge = true, cartId } = {}) {
      const api = rootGetters['API/cart']
      const client = getters.client
      const site = getters.site
      const { useCart, siteUsesRedirectOnly } = configGetters

      if (!client || !site) {
        throw new Error('Cart cannot load cart without client and site')
      }

      // This currently does not warn or error because, as of writing,
      // forms workflow also attempts to loadCart and adding a warning/error
      // would be very noisy.
      if (!useCart) {
        return
      }

      if (!cartId) {
        try {
          cartId = localStorage.getItem(getters.localStorageKey)
        }
        catch (error) {
          console.error('Unable to load cart from local storage due to incognito window')
        }
      }

      const opts = { merge, create }
      if (cartId) {
        // eslint-disable-next-line camelcase
        opts.cart_id = cartId
      }

      let cartData
      const loggedIn = Boolean(api.jwt)
      // TODO PSC-4491 Do something similar to this
      // whenever cartId is passed in directly(vs. retrieved from stash)
      // I think the only way cartId gets set before cache retrieval
      // is by retrieval from the URL in @grantstreet/govhub-vue/src/install.js
      if (cartId && siteUsesRedirectOnly) {
        if (loggedIn) {
          cartData = (await api.claimCart(client, site, cartId))?.data
        }
        else {
          // If the user logs in, they can claim the cart from here
          cartData = (await api.getCart(client, site, cartId))?.data
        }
      }
      else {
        cartData = (await api.vivifyCart(client, site, opts))?.data
      }

      if (!cartData) {
        throw new Error(`Couldn't initialize cart (id=${cartId})`)
      }

      if (loggedIn) {
        try {
          // Current cart will be tracked on the back end.
          localStorage.removeItem(getters.localStorageKey)
        }
        catch (error) {
          console.error('Unable to remove cart key from local storage due to incognito window')
        }
      }
      else {
        try {
          // Stash locally for anonymous users.
          localStorage.setItem(getters.localStorageKey, cartData.id)
        }
        catch (error) {
          console.error('Unable to store cart in local storage due to incognito window')
        }
      }
      try {
        await dispatch('inflateAndUpdateCart', cartData)
      }
      catch (error) {
        throw new Error(`Couldn't initialize cart (id=${cartData.id}): ` + error)
      }
      dispatch('checkCartForRestrictions', cartData)
    },

    // Locks the cart which prevents items from being added, removed,
    // or item quantities changed.
    async lockCart ({ getters, rootGetters, dispatch }) {
      const api = rootGetters['API/cart']
      const { data } = await api.lockCart(getters.client, getters.site, getters.cartId)
      await dispatch('inflateAndUpdateCart', data)
    },

    // Adds the passed items to the current cart by sending them to the Cart
    // service.
    //
    // The `items` parameter is an array of objects containing:
    //  - path: payable path for the item
    //  - amount
    //  - quantity (optional, defaults to 1)
    //
    // The `skipUpdates` parameter is a Boolean that uses Cart Service's
    // `/items_skip_updates` endpoint, which adds items to a user's cart without
    // checking existing items for updates. This defaults to false, meaning the
    // typical add items behavior (with checking existing items) is used.
    async addToCart ({ getters, rootGetters, dispatch, commit, state }, { items = [], skipUpdates = false }) {
      if (getters.cart.locked) {
        throw new Error('addToCart: cart is locked')
      }
      items = items.map(item => {
        // eslint-disable-next-line camelcase
        item.user_parameters = item.userParameters
        item.userParameters = null
        for (const key of ['path', 'amount']) {
          if (!item.hasOwnProperty(key)) {
            throw new Error(`addToCart: every item must contain "${key}"`)
          }
        }

        // Set restricted adaptor because this item has this restriction
        if (item.cartRestrictionLevel === 'adaptor') {
          commit('setRestrictedAdaptor', item.adaptor)
        }

        // XXX: Shouldn't we update amount before posting? (Non-MVP.)
        item.amount = item.amount <= 0 ? null : decimalFormat(item.amount)

        // If the item has details, they can only remain with the item if and
        // only if the item also contains a verification JWT. In this case,
        // we'll update the details to use the raw payable data rather than the
        // TypeScript model.
        //
        // Otherwise, we'll set the details to null in the item being added as
        // they shouldn't normally contain them (the request will be rejected).
        // It also reduces bloat for requests and logs.
        if (item.details) {
          if (item.details?.verificationJwt) {
            item.details = item.details.raw
          }
          else {
            item.details = null
          }
        }

        return item
      })

      let cartData
      if (skipUpdates) {
        const { data } = await rootGetters['API/cart'].addToCartSkipUpdates(getters.client, getters.site, getters.cartId, items)
        cartData = data
      }
      else {
        const { data } = await rootGetters['API/cart'].addToCart(getters.client, getters.site, getters.cartId, items)
        cartData = data
      }
      await dispatch('inflateAndUpdateCart', cartData)

      // if the user opted into autopay for all renewals, enroll each of the new
      // added payables in autopay if they support yearly autopay.
      if (state.autoenrollRenewals) {
        const addedPayablePaths = items.map(item => item.path)
        state.cart.items.forEach(item => {
          if (addedPayablePaths.includes(item.payable.path) && item.payable.isYearlyAutopayEligible) {
            item.enrollInAutopay = true
          }
        })
      }
    },

    async removeFromCart ({ getters, rootGetters, dispatch, commit }, items = []) {
      if (getters.cart.locked) {
        throw new Error('removeFromCart: cart is locked')
      }
      if (!Array.isArray(items)) {
        items = [items]
      }
      if (!items.length) {
        throw new RangeError('removeFromCart: Must pass required parameter items.')
      }

      let cartData
      for (const item of items) {
        if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
          throw new Error('removeFromCart: item object must have contain "id"')
        }

        ;({ data: cartData } = await rootGetters['API/cart'].removeFromCart(getters.client, getters.site, getters.cartId, item.id))

        // Remove any restricted adaptor as the cart is empty
        // TODO: PSC-12134 check if there are still any restricted adaptor items
        // in the cart after removing this item from the cart
        if (cartData?.items?.length === 0) {
          commit('setRestrictedAdaptor', null)
        }
      }

      if (cartData) {
        await dispatch('inflateAndUpdateCart', cartData)
      }
    },

    async modifyItemQuantity ({ getters, rootGetters, dispatch }, { item, quantity }) {
      if (typeof quantity !== 'string') {
        throw new TypeError('Quantity must be a string')
      }
      // XXX: The interface here is odd
      item.quantity = Number.parseInt(quantity)

      const { data } = await rootGetters['API/cart'].updateCartItem(getters.client, getters.site, getters.cartId, item)
      await dispatch('inflateAndUpdateCart', data)
    },

    async modifyItemAmount ({ getters, rootGetters, dispatch }, { item, amount }) {
      if (typeof amount !== 'string') {
        throw new TypeError('Amount must be a string')
      }
      if (!/^[\d,]+\.\d\d$/.test(amount)) {
        // eslint-disable-next-line no-useless-escape
        throw new Error('Amount must match format /^[,\d]+\.\d\d$/')
      }

      const { data } = await rootGetters['API/cart'].updateCartItem(getters.client, getters.site, getters.cartId, {
        ...item,
        amount,
        // eslint-disable-next-line camelcase
        user_parameters: item.userParameters,
      })
      await dispatch('inflateAndUpdateCart', data)
    },

    async modifyItemFreeformField ({ getters, rootGetters, dispatch }, { item, reportingKey, pexKey, value }) {
      if ((typeof reportingKey !== 'string' && typeof pexKey !== 'string') || typeof value !== 'string') {
        throw new TypeError('Field key and value must be strings')
      }
      if (!reportingKey && !pexKey) {
        throw new Error('Field Key must have a value')
      }

      if (!item.userParameters) item.userParameters = {}
      if (reportingKey) {
        item.userParameters[reportingKey] = value
      }
      else {
        item.userParameters[pexKey] = value
      }

      const { data } = await rootGetters['API/cart'].updateCartItem(getters.client, getters.site, getters.cartId, {
        ...item,
        // eslint-disable-next-line camelcase
        user_parameters: item.userParameters,
      })
      await dispatch('inflateAndUpdateCart', data)
    },

    async modifyItemPayable ({ getters, rootGetters, dispatch }, { item, payable }) {
      if (typeof payable !== 'object' || typeof payable.raw !== 'object') {
        throw new TypeError('Payable must be a Payable')
      }
      if (!payable) {
        throw new Error('Payable must have a value')
      }

      const { data } = await rootGetters['API/cart'].updateCartItem(getters.client, getters.site, getters.cartId, {
        ...item,
        amount: payable.amount,
        confirmedDetails: payable.raw,
        // eslint-disable-next-line camelcase
        user_parameters: item.userParameters,
      })

      await dispatch('inflateAndUpdateCart', data)
    },

    async emptyCart ({ getters, rootGetters, dispatch, commit }) {
      // XXX: If you're doing this after a checkout of some kind, see retireCart

      // XXX: Error handling and parameter validation
      const { data } = await rootGetters['API/cart'].emptyCart(getters.client, getters.site, getters.cartId)

      // Remove any restricted adaptor as the cart is empty
      commit('setRestrictedAdaptor', null)

      await dispatch('inflateAndUpdateCart', data)
    },

    // Tenders saved in GovHub by anonymous users are tied to a specific cart
    // via the "credential." Now that we have multi-use (expiring) anonymous
    // tenders, there's a risk that someone could use the same tender for
    // multiple check-outs of the same cart. In the worst case, on a public
    // computer, this could mean someone checks out using a previous users
    // expiring tender. To prevent this risk, instead of emptying carts after
    // check-out, we should retire them.
    async retireCart ({ getters, rootGetters, dispatch, commit }) {
      commit('setCartLoadPromise', (async () => {
        const api = rootGetters['API/cart']
        await api.expireCart(getters.client, getters.site, getters.cartId)

        localStorage.removeItem(getters.localStorageKey)

        const cart = (await api.createCart(getters.client, getters.site, {}))?.data
        await dispatch('inflateAndUpdateCart', cart)

        const { jwt } = await dispatch('getTokens')
        EventBus.$emit('cart.replaced', jwt)
      })())
    },

    // Asks the Cart service to get the cart fees from PEx. This is invoked when
    // the user gets to the payment page, at which point we check all available
    // tender types. It's also checked again when the user gets to the confirmation
    // page. The extra check is primarily for error-handling, so that we have a
    // logical place to intervene and show the error.
    async calculateFees ({ rootGetters, getters, commit }) {
      // If this site doesn't exist, E-Wallet will not be loaded
      if (!configState.config.eWallet) return {}

      // Outbound redirect clients don't exist in Paycore, so don't
      // calculate fees for them

      const { useExternalCheckout } = configGetters

      if (useExternalCheckout) {
        return {}
      }

      const allowedMethods = getters.enabledPayableMethods

      // If we get no valid payment methods, then skip the calls
      if (allowedMethods.length === 0) {
        return {}
      }

      const api = rootGetters['API/cart']
      let fees = {}

      // Make a fee request if there are items in the cart
      if (getters.count) {
        // Handle getting the paypal merchantID since v3 fees doesn't return it
        if (allowedMethods.includes('paypal')) {
          const res = await api.calculateFeesSingleTender(getters.client, getters.site, getters.cartId, { type: 'paypal' })

          if (!res || !res.data) {
            throw new Error('calculateFeesSingleTender: unable to parse response: ' + JSON.stringify(res))
          }

          // If we have at least 1 merchant id, record it
          if (res.data?.charges?.[0]?.paypal_merchant_id) {
            // Extract all merchant ids
            let merchantIDs = res.data.charges.map(charge => charge.paypal_merchant_id)
            // Remove duplicates
            merchantIDs = [...new Set(merchantIDs)]
            // Record
            commit('setMerchantIDs', merchantIDs)
          }
        }

        const res = await api.calculateFees(getters.client, getters.site, getters.cartId, allowedMethods)
        if (!res || !res.data) {
          throw new Error('calculateFees: unable to parse response: ' + JSON.stringify(res))
        }
        else {
          fees = res.data
        }
      }
      else {
        for (const method of allowedMethods) {
          fees[method] = '0.00'
        }
      }

      commit('setConvenienceFees', fees)

      return fees
    },

    async calculateItemFees ({ rootGetters, getters }, { payablePath, amount, client, site }) {
      // If this site doesn't exist, E-Wallet will not be loaded
      if (!configState.config.eWallet) return {}

      if (!client) client = getters.client
      if (!site) site = getters.site
      amount = String(amount)
      const { data } = await rootGetters['API/cart'].calculateItemFees(
        client,
        site,
        payablePath,
        amount,
      )
      return data
    },

    async populateTransactions ({ dispatch, commit }, tenderData) {
      const { transactions, totals } = await dispatch('fetchTransactions', tenderData)
      commit('setTransactions', transactions)
      commit('setTotals', totals)
      return transactions
    },

    /*
      tenderData can be the following:
        tenderData {
          ewalletToken : string
        }
        tenderData {
          bankAccountNumber : string
          bankAccountType : string
        }
    */
    async fetchTransactions ({ rootGetters, rootState, getters }, { tenderType, tenderSource, tenderData }) {
      // If this site doesn't exist, E-Wallet will not be loaded
      if (!configState.config.eWallet) {
        return {}
      }

      const tender = {
        type: tenderType,
      }

      const loggedIn = Boolean(rootGetters['API/cart'].jwt)

      /*
        Here we fetch different kinds of transactions
        based on the type of auth payment
      */
      // Non-tokenized card payments
      if ((configState.config.cart.useVaultToken && tenderType === 'card') ||
          (!loggedIn && tenderType === 'card') ||
          tenderSource === 'apple' ||
          tenderSource === 'google') {
        tender.vaultToken = rootState.PayHub.dataVaultToken
      }
      // Delayed $0 Auth bank payments
      else if (tenderType === 'bank' && !loggedIn) {
        tender.bankAccountNumber = tenderData.bankAccountNumber
        tender.bankAccountType = tenderData.bankAccountType
      }
      // $0 auth payments (EWallet)
      else {
        tender.ewalletToken = tenderData.ewalletToken
      }

      const { data } = await rootGetters['API/cart'].getTransactions(getters.client, getters.site, getters.cartId, tender)

      return {
        transactions: data.transactions.map(getters.createAndInflateTransaction),
        totals: {
          immediateItemTotal: data.immediate_item_total,
          immediateFeeTotal: data.immediate_fee_total,
          delayedItemTotal: data.delayed_item_total,
          delayedFeeTotal: data.delayed_fee_total,
        },
      }
    },

    getCart ({ getters, rootGetters }, cartId) {
      const api = rootGetters['API/cart']
      return api.getCart(getters.client, getters.site, cartId)
    },

    // XXX: We should be really careful of wholesale updates to the cart. One of
    // the main concerns is that we could introduce changes (errors) into the BE
    // copy shortly before a checkout thereby nullifying or reducing the
    // protection we get from comparing the POSTed FE cart to the DB copy during
    // check_out. That would be particularly bad if it happened between user
    // review and check_out.
    updateDBCart ({ getters, rootGetters }, { cartId, cart }) {
      const api = rootGetters['API/cart']

      // This is a better way to remove props than the delete operator.
      // See psc-js/utils/objects.js for more.
      const {
        // Disallowed keys
        // Locked should not be modified here and should instead be in a dedicated
        // function
        'id': deletedId,
        'user_id': deletedUserId,
        'client': deletedClient,
        'site': deletedSite,
        'created': deletedCreated,
        'modified': deletedModified,
        'locked': deletedLocked,
        // What we want to keep
        ...sanitized
      } = cart
      cart = sanitized

      cart.items = cart.items.map(item => {
        // This is a better way to remove props than the delete operator.
        // See psc-js/utils/objects.js for more.
        const {
          // Disallowed item keys
          'id': deletedId,
          'details': deletedDetails,
          // What we want to keep
          ...sanitized
        } = item
        return sanitized
      })

      return api.updateCart(getters.client, getters.site, cartId, cart)
    },

    async getTokens ({ rootGetters, getters, dispatch }) {
      const tokenResponse = await rootGetters['API/cart'].getTokens(getters.client, getters.site, getters.cartId)
      return dispatch('handleGetTokensResponse', tokenResponse)
    },

    async getTokensForUser ({ rootGetters, getters, dispatch }, { userId }) {
      const tokenResponse = await rootGetters['API/cart'].getTokensForUser(getters.client, getters.site, getters.cartId, userId)
      return dispatch('handleGetTokensResponse', tokenResponse)
    },

    handleGetTokensResponse ({ commit }, { data }) {
      const dvToken = data.dv_token
      const jwt = data.ewallet_token

      // XXX: Should these live in Cart or E-Wallet?
      commit('PayHub/setDataVaultToken', dvToken, { root: true })
      commit('PayHub/setEncodedJwt', jwt, { root: true })

      return { dvToken, jwt }
    },

    async approvePaypalOrder ({ rootGetters, rootState, getters, state }, { orderId, contactPreference, loggedIn }) {
      const res = await rootGetters['API/cart'].approvePaypalOrder(getters.client, getters.site, getters.cartId, orderId, contactPreference, loggedIn)
      res.transactions = res.transactions.map(getters.createAndInflateTransaction)
      return res
    },

    async checkOut ({ rootGetters, rootState, getters, state }, { tender, extraFields = {}, delivery = {}, deliveryMethod, scheduledPaymentEnrollments }) {
      if (!(tender instanceof Tender)) {
        throw new Error('checkOut: tender must be a Tender object')
      }

      const fee = getters.chosenConvenienceFee

      const payment = {
        type: tender.type,
        // eslint-disable-next-line camelcase
        tender_name: tender.name,
        // eslint-disable-next-line camelcase
        tender_summary: tender.typeWithLastDigits(
          i18n.global.t('receipt.ending'),
        ),

        // eslint-disable-next-line camelcase
        immediate_item_total: state.totals.immediateItemTotal,
        // eslint-disable-next-line camelcase
        immediate_fee_total: state.totals.immediateFeeTotal,
        // eslint-disable-next-line camelcase
        delayed_item_total: state.totals.delayedItemTotal,
        // eslint-disable-next-line camelcase
        delayed_fee_total: state.totals.delayedFeeTotal,

        // XXX: It's awkward to have to convert the number to string.
        amount: decimalFormat(getters.cart.subtotal),
        // eslint-disable-next-line camelcase
        fee_amount: decimalFormat(fee),
      }

      const loggedIn = Boolean(rootGetters['API/cart'].jwt)
      // Use DataVault tokens for credit cards when configured to do so, or
      // when paying for immediate items with Apple Pay and Google Pay.
      if ((configState.config.cart.useVaultToken && tender.type === 'card') ||
          (['apple', 'google'].includes(tender.source) && !getters.cart.hasDelayedItems)) {
        // eslint-disable-next-line camelcase
        payment.vault_token = rootState.PayHub.dataVaultToken
      }
      // Otherwise, use the E-Wallet token, which includes Apple Pay and Google
      // Pay tenders for payments with delayed items.
      else {
        // eslint-disable-next-line camelcase
        payment.ewallet_token = tender.ewalletToken
      }
      if (tender.type === 'bank' && !loggedIn) {
        // eslint-disable-next-line camelcase
        payment.bank_account_number = state.bankAccountNumber
      }
      // eslint-disable-next-line camelcase
      payment.logged_in = Boolean(rootGetters['API/cart'].jwt)

      // These are partially redundant with tender_name and
      // tender_summary, but the Payables "paid" event expects
      // these fields independently
      // eslint-disable-next-line camelcase
      payment.last_digits = tender.lastDigits || ''

      if (tender.type === 'card') {
        // eslint-disable-next-line camelcase
        payment.card_brand = tender.cardBrand || ''
      }

      // Fill in the paypal email field if the payment is paypal,
      // this is used for tokenized paypal
      if (tender.type === 'paypal') {
        // eslint-disable-next-line camelcase
        payment.paypal_email = tender.paypalEmail || ''
      }
      const billing = tender.billingAddress || {}

      payment.billing = {
        email: extraFields.email,
        phone: extraFields.phone,
        name: tender.userName,
        address: [billing.address1, billing.address2].filter(address => address).join('\n'),
        city: billing.city,
        state: billing.state,
        zip: billing.postalCode,
        country: billing.country,
      }

      // eslint-disable-next-line camelcase
      payment.contact_preference = extraFields.contactPreference

      if (delivery && Object.keys(delivery).length) {
        payment.delivery = {
          name: delivery.name,
          address: [delivery.address1, delivery.address2].filter(address => address).join('\n'),
          city: delivery.city,
          state: delivery.state,
          zip: delivery.postalCode,
          country: delivery.country,
        }
      }

      if (deliveryMethod) {
        // eslint-disable-next-line camelcase
        payment.delivery_method = deliveryMethod
      }

      if (scheduledPaymentEnrollments) {
        // eslint-disable-next-line camelcase
        payment.scheduled_payment_enrollments = {
          'additional_messaging_text': scheduledPaymentEnrollments.additionalMessagingText,
          enrollments: scheduledPaymentEnrollments.enrollments.map(enrollment => ({
            'cart_item_id': enrollment.cartItemID,
            'agreement': enrollment.agreement,
            // The frequency rule doesn't need to be converted to snakecase as
            // Schedpay uses camelcase in its spec.
            'frequency_rule': enrollment.frequencyRule,
          })),
        }
      }

      const res = await rootGetters['API/cart'].checkOut(getters.client, getters.site, getters.cartId, payment)
      res.data.transactions = res.data.transactions.map(getters.createAndInflateTransaction)
      return res
    },

    async setPrepopulateCartWarnings ({ commit }, { warnings = [] }) {
      commit('setPrepopulateCartWarnings', warnings)
    },

    async checkCartForRestrictions ({ commit }, cartData) {
      const adaptorItem = cartData.items.find(item => item.details.cart_restriction_level === 'adaptor')
      if (adaptorItem) {
        commit('setRestrictedAdaptor', adaptorItem.details.adaptor)
      }

      const itemCategoryItem = cartData.items.find(item => item.details.cart_restriction_level === 'item_category')
      if (itemCategoryItem) {
        commit('setRestrictedItemCategory', itemCategoryItem.details.item_category)
      }
    },

    async createCartWithPickUpFlag ({ dispatch }, { cart, delivery }) {
      const cartUserParameters = {}
      cart.items.forEach(item => {
        cartUserParameters[item.payable.raw.path] = item.userParameters
      })

      const [ getRes, items ] = await Promise.all([
        dispatch('getCart', cart.id),
        dispatch('searchPayablesWithPickUpFlag', { cart, delivery }),
      ])
      const dbCart = getRes.data
      dbCart.items = items.map(payable => {
        return {
          ...payable.raw,
          // eslint-disable-next-line camelcase
          user_parameters: cartUserParameters[payable.raw.path],
        }
      })

      const putRes = await dispatch('updateDBCart', {
        cartId: dbCart.id,
        cart: dbCart,
      })

      return putRes.data
    },

    /**
     * @throws Throws an error if the Payables search fails
     */
    async searchPayablesWithPickUpFlag (_store, { cart, delivery }) {
      let deliveryMethod = delivery || 'none'
      if (deliveryMethod === 'pickUp') {
        deliveryMethod = 'pick_up'
      }

      return (
        await Promise.all(cart.items.map(async item => {
          const path = item.payable.raw.path
          const adaptor = item.payable.raw.adaptor
          const version = path.match(/v\d+/).shift()
          const payablesAdaptor = `${adaptor}/${version}`

          const queryString = path.split('?').pop()
          const query = Object.fromEntries(new URLSearchParams(queryString))

          if (query.hasOwnProperty('type') && query.type === 'renewal') {
            const newQuery = { ...query }
            if (deliveryMethod) {
              // eslint-disable-next-line camelcase
              newQuery.delivery_method = deliveryMethod
            }
            const { payables: payable } = await searchPayables({
              payablesAdaptor,
              data: { query: newQuery },
              language: i18n.global.locale.value,
            })
            return payable.shift()
          }
          else {
            return item.payable
          }
        }))
      )
    },

    getCartRenewalType ({ state }) {
      // All items must have the same delivery method, otherwise
      // treat the cart as having 'none' delivery method
      let sharedDeliveryMethod
      for (const item of state.cart.items) {
        const path = item.payable.raw.path
        if (!path.includes('?')) {
          continue
        }

        const queryString = path.split('?').pop()
        const query = new URLSearchParams(queryString)

        if (query.has('delivery_method')) {
          let deliveryMethod = query.get('delivery_method')
          deliveryMethod = deliveryMethod === 'pick_up' ? 'pickUp' : deliveryMethod
          // If at anytime we have a cart with payables of mixed delivery methods, bail out with
          // 'none' so that validation requires the user to re-select a method to be applied
          sharedDeliveryMethod ??= deliveryMethod
          if (sharedDeliveryMethod !== deliveryMethod) {
            return 'none'
          }
        }
      }
      return sharedDeliveryMethod || 'none'
    },

    async autoenrollRenewalsInAutopay ({ state, dispatch }, enroll) {
      if (state.autoenrollRenewals !== null) {
        return
      }
      state.autoenrollRenewals = enroll

      // Bail out if the user declined autopay
      if (!enroll) return

      // Filter cart items for autopay eligible items
      const eligibleItems = state.cart.items.filter(item => item.payable.isYearlyAutopayEligible)
      if (eligibleItems.length === 0) return

      // We must only enroll renewals without existing schedules in autopay.
      // Wait for all of the requests to schep to settle before modifying the
      // state.
      const requests = eligibleItems.map(item => dispatch('SchedPay/checkForExistingSchedules', item.payable.savePath, { root: true }))
      await Promise.allSettled(requests)

      for (const item of eligibleItems) {
        // Note, this will resolve immediately without an additional network
        // request
        const isAlreadyEnrolled = await dispatch('SchedPay/checkForExistingSchedules', item.payable.savePath, { root: true })
        if (!isAlreadyEnrolled) {
          item.enrollInAutopay = true
        }
      }
    },
  },
}
