<template>
  <div>
    <LoadingBars
      v-if="mustPreventDuplicatePlans && !userHasActivePlan && payableHasActivePlan ===
        null"
    />
    <span
      v-else
      :id="'autopay-checkbox-container' + _uid"
    >
      <span
        v-if="!disabled && !readOnly"
        @click="emitEventIfAnonymous"
      >
        <!--
        Bootstrap doesn't _actually_ respect the value of 'checked' in
        :v-model="checked". The 'checked' setter method could no-op without
        changing the state, but clicking on the checkbox would still result in
        the checkbox visually appearing checked.
        Bootstrap also doesn't provide a :value prop to make the checkbox a
        controlled component.
        This is troublesome because when an anonymous user clicks this, the
        checked state should *NOT* be modified. (Doing so has side effects).
        The easiest way to achieve this is to disable the checkbox,
        and to catch the click event on an ancestor element in the dom (the
        encapsulating span element).
        We still want the user to click on this checkbox when it is in this
        'disabled' state though, so we will apply the secretlyDisabled class,
        which will hide the gray background bootstrap applies.
      -->
        <b-form-checkbox
          v-if="!anonymousUserWithAutopayAvailable"
          v-model="checked"
          class="align-items-start mr-1"
          inline
          data-test="autopay-checkbox"
          :class="{'secretlyDisabled': !user.loggedIn && selectedTenderSupportsAutopay}"
          :disabled="!selectedTenderSupportsAutopay || !user.loggedIn"
          tabindex="0"
        >
          {{ label }}
        </b-form-checkbox>
        <b-link
          v-if="anonymousUserWithAutopayAvailable"
          class="align-items-start mr-1"
          tabindex="0"
          @click="emitEventIfAnonymous"
        >
          {{ label }}
        </b-link>
      </span>
      <span
        v-else-if="disabled || checked"
        class="align-items-start mr-1"
        data-test="autopay-checkbox"
      >
        {{ label }}
      </span>

      <svgicon
        v-if="showIcon"
        :id="'autopay-checkbox' + _uid"
        :aria-label="setTooltipAriaLabel"
        :fill="false"
        role="tooltip"
        icon="question-mark-circle"
        width="1.25rem"
        height="1.25rem"
        class="icon"
        tabindex="0"
      />
      <!-- When the checkbox is disabled, we want the tooltip to be displayed
      on the containing element, so that hovering anywhere on the text displays
      the tooltip. Otherwise, we want the tooltip to be displayed when hovering
      over the '?' icon. This component must be re-rendered each time this hover
      target changes; a dynamic key is used for this purpose.
      The i18n locale is included in the key to force the tooltip to re-render
      when the locale changes. This ensures the tooltip text correctly gets
      translated to the new locale.-->
      <b-tooltip
        v-if="showIcon"
        :id="'autopay-checkbox-container-tooltip' + _uid"
        :key="'autopay-checkbox-tooltip' + _uid + tooltipTarget + $i18n.locale"
        :target="tooltipTarget + _uid"
        placement="bottom"
        triggers="hover focus"
      >
        <span
          v-dompurify-html="tooltipText"
        />
      </b-tooltip>
    </span>

  </div>
</template>

<script>
import { useGsgUser } from '@grantstreet/user'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

/**
 * Note: when the autopay checkbox is selected for Renewals, a side effect will
 * run that may replace the payable in the cart. See the watcher in:
 * @grantstreet/payables/src/components/description/display-types/rex-vehicle-registration/RenewalPayableDescription.vue
 */

export default {
  emits: ['anonymous-selection', 'input'],

  setup: () => ({
    user: useGsgUser().user,
  }),

  components: {
    LoadingBars,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    payable: {
      type: Object,
      default: () => ({}),
    },
    userHasActivePlan: {
      type: Boolean,
      default: false,
    },
    payableHasActivePlan: {
      type: Object,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    receipt: {
      type: Boolean,
      default: false,
    },
    tender: {
      type: Object,
      default: null,
    },
    selectedTenderSupportsAutopay: {
      type: Boolean,
      default: false,
    },
    isAlreadyEnrolledInAutopay: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    checked: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    label () {
      let key = 'autopay_checkbox.label'

      if (this.receipt) {
        key += '.receipt'
      }
      else if (this.disabled) {
        key += '.disabled'
      }
      else if (this.readOnly) {
        key += '.read_only'
      }
      else {
        key += '.default'
      }
      return this.$t(key)
    },

    tooltipTarget () {
      return ((!this.selectedTenderSupportsAutopay || this.disabled) && !this.readOnly) || this.isAlreadyEnrolledInAutopay
        ? 'autopay-checkbox-container'
        : 'autopay-checkbox'
    },

    tooltipText () {
      let key = 'autopay_checkbox.tooltip_text'
      const args = {}

      if (this.disabled) {
        if (this.user?.loggedIn) {
          const subkey = this.userHasActivePlan ? 'by_this_user' : 'by_another_user'
          key += `.already_enrolled.${subkey}`
        }
        else {
          key += '.already_enrolled.by_a_user'
        }
      }
      // On the checkout page only, display a tooltip informing the user that
      // the selected tender is not supported for autopay.
      else if (!this.selectedTenderSupportsAutopay && !this.readOnly && !this.receipt) {
        key += '.bad_tender'
      }
      else if (this.receipt) {
        key += '.receipt'
        args.tenderSummary = this.tender?.description.toLowerCase()
      }
      else if (this.readOnly) {
        key += '.read_only'
      }
      // REx renewals will display "each year" in the tooltip
      // rather than "each billing cycle"
      else if (this.payable?.displayType === 'rex-vehicle-registration') {
        key += '.rex'
      }
      else {
        key += '.default'
      }

      return this.$t(key, args)
    },

    disabled () {
      return this.userHasActivePlan || (this.mustPreventDuplicatePlans && this.payableHasActivePlan)
    },

    // We want to display a link to prompt the user to sign in, if they are anonymous
    // and can enroll in autopay with the selected tender
    anonymousUserWithAutopayAvailable () {
      return !this.user?.loggedIn && this.selectedTenderSupportsAutopay
    },

    mustPreventDuplicatePlans () {
      return this.payable.scheduledPaymentsConfig?.denyDuplicatePlans
    },

    // For screen readers, we want to set the aria-label of the tooltip icon to the
    // tooltip message and remove any <br/> html in the message
    setTooltipAriaLabel () {
      return this.tooltipText.replace(/<br\/>/g, ' ')
    },

    showIcon () {
      return (!this.readOnly || this.checked) || this.receipt || this.isAlreadyEnrolledInAutopay
    },
  },

  methods: {
    // If the current user is anonymous, emit the anonymous selection event.
    // Otherwise no-op. This will display the schep modal prompting the user to
    // login.
    // If the currently selected tender does not support autopay, the tooltip
    // will inform them to select a different tender. Clicking on the checkbox
    // should not display the login modal.
    emitEventIfAnonymous () {
      if (!this.user?.loggedIn && this.selectedTenderSupportsAutopay) {
        this.$emit('anonymous-selection')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  margin-top: -.25rem;
}

// This will remove the gray background color applied to disabled checkboxes
::v-deep .secretlyDisabled .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: white;
}

</style>
